import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';
import parse from 'html-react-parser';

const NumericSlider = ({ question }) => {
  const { min, max, step, options, sx, orientation, questionId, questionText } = question;
  const responses = useSelector((state) => state.responses || {});
  const initialInputValue = responses[questionId] ?? null;
  const [inputValue, setInputValue] = useState(initialInputValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (responses[questionId] !== undefined) {
      setInputValue(responses[questionId]);
    }
  }, [responses, questionId]);

  useEffect(() => {
    if (inputValue !== null) {
      dispatch(
        setCurrentQuestion({
          id: questionId,
          response: inputValue,
        })
      );
    }
  }, [inputValue, dispatch, questionId]);

  const handleSliderChange = (event, value) => {
    let _value = min == value ? null : value
    setInputValue(_value);
    dispatch(
      setCurrentQuestion({
        id: questionId,
        response: value,
        selectedOption: value,
      })
    );
  };

  const handleInputFieldChange = (e) => {
    let value = e.target.value === '' ? min : Number(e.target.value);
    if (value < min) value = min;
    if (value > max) value = max;
    setInputValue(value);

    dispatch(
      setCurrentQuestion({
        id: questionId,
        response: value,
        selectedOption: value,
      })
    );
  };

  const marks = options.map(option => ({
    value: Number(option.code),
    label: option.label,
  }));

  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>


      <div className="mt-4 flex-wrap">
        <CustomResponse>
          <Slider
            id={questionId?.toString()}
            min={min}
            max={max}
            value={inputValue ?? min}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            marks={marks}
            step={step}
            sx={sx}
            orientation={orientation}
          />
        </CustomResponse>
      </div>

      <div className="mt-4 w-40">
        <CustomResponse className="flex flex-col">
          Input Slider Value:&nbsp;
          <Input
            type="number"
            value={inputValue}
            onChange={handleInputFieldChange}
            onBlur={handleInputFieldChange}
            max={max}
            step={step}
          />
        </CustomResponse>
      </div >

    </>
  );
};

export default NumericSlider;
