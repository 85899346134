import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import parse from 'html-react-parser';
import { Label, Input } from 'reactstrap';
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';

const BooleanQuestion = ({ question }) => {
  const { options = [], questionId, questionText, questionInstruction } = question;
  const responses = useSelector((state) => state.responses || {}); // Retrieve responses from Redux
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    // Update state when responses change
    if (responses && responses[questionId]) {
      setSelectedOption(responses[questionId] || '');
    }
  }, [responses, questionId]);

  useEffect(() => {
    const response = selectedOption || null;
    // Only dispatch if there is a selection
    if (response !== null) {
      dispatch(setCurrentQuestion({ id: questionId, response }));
    }
  }, [selectedOption, dispatch, questionId]);

  const handleChange = (event) => {
    const code = event.target.value;
    setSelectedOption(code); // Set the selected option based on code
  };

  return (
    <div className='flex flex-col'>
      {question.questionText &&
        (<CustomQuestion
          id={`${question.questionId}_text`}
          key={`${question.questionId}_text`}
          text={parse(question.questionText)}
        />
        )}
      {question.questionInstruction &&
        (<CustomInstruction
          id={`${question.questionId}_instruction`}
          key={`${question.questionId}_instruction`}
          text={parse(question.questionInstruction)}
        />
        )}
      <div className="mt-4">
        <CustomResponse className='flex-col'>
          {options.map((option) => (
            <Label key={option.code}>
              <input
                type="radio"
                className='mr-3 mb-3'
                name={questionId}
                value={option.code} 
                checked={selectedOption === option.code}
                onChange={handleChange}
                style={{ accentColor: selectedOption === option.code ? option.color : undefined }}
              />
              {option.label}
            </Label>
          ))}
        </CustomResponse>
      </div>
    </div>
  );
};

export default BooleanQuestion;
