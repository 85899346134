
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";
import { store } from "./redux/store/store.js";
import RenderSurvey from "./components/RenderSurvey.js";
const App = ()=>{
  return (
    <div className="App">
      {/* New Survey Tool */}
      <Provider store={store}>
      <RenderSurvey></RenderSurvey>
      </Provider>
      {/* <TestComponents></TestComponents> */}
    </div>
  );
}
export default App;
