export const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;

  while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

const randomizeOptions = (options) => {
  const anchoredOptions = options.filter(option => option.isAnchor);
  const nonAnchoredOptions = options.filter(option => !option.isAnchor);

  const shuffledNonAnchoredOptions = shuffleArray(nonAnchoredOptions);

  const randomizedOptions = [];
  let anchoredIndex = 0;
  let nonAnchoredIndex = 0;

  for (let i = 0; i < options.length; i++) {
      if (options[i].isAnchor) {
          randomizedOptions.push(anchoredOptions[anchoredIndex++]);
      } else {
          randomizedOptions.push(shuffledNonAnchoredOptions[nonAnchoredIndex++]);
      }
  }

  return randomizedOptions;
};

export default randomizeOptions;

export const randomizeOptionsWithinGroups = (groups, options) => {
  return groups.map(group => ({
      ...group,
      options: randomizeOptions(group.options.map(optionIndex => {
          return options.find(option => option.code === optionIndex.toString());
      }))
  }));
};

export const randomizeGroupsOrder = (groups) => {
  const anchoredGroups = groups.filter(group => group.isAnchor);
  const nonAnchoredGroups = groups.filter(group => !group.isAnchor);

  const shuffledNonAnchoredGroups = shuffleArray(nonAnchoredGroups);

  const randomizedGroups = [];
  let anchoredIndex = 0;
  let nonAnchoredIndex = 0;

  for (let i = 0; i < groups.length; i++) {
      if (groups[i].isAnchor) {
          randomizedGroups.push(anchoredGroups[anchoredIndex++]);
      } else {
          randomizedGroups.push(shuffledNonAnchoredGroups[nonAnchoredIndex++]);
      }
  }

  return randomizedGroups;
};

export const getOptionsNotInGroups = (groups, options) => {
  const optionsInGroups = groups.flatMap(group => group.options);
  const optionsInGroupsSet = new Set(optionsInGroups.map(option => option.code));
  return options.filter(option => !optionsInGroupsSet.has(option.code));
};
