  import React, { useEffect, useState } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice.js";
  import './ImageSingleSelect.css';
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlassPlus,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { CustomResponse } from "../../theme/ResponseLabel";
import parse from "html-react-parser";
import { CustomQuestion } from "../../theme/QuestionLabel";
import { CustomInstruction } from "../../theme/InstructionLabel";

  const ImageSingleSelectComponent = ({ question }) => {
    const dispatch = useDispatch();
    const storedResponses = useSelector(store => store.responses);
    const [selectedValues, setSelectedValues] = useState([]);
    const [zoomLevels, setZoomLevels] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [modalImageCode, setModalImageCode] = useState(null);

    // useEffect(() => {
 
    //   if (storedResponses && storedResponses[question.questionId]) {
    //     const response = storedResponses[question.questionId];
    //     setSelectedValues(response);
    //   }
    // }, [storedResponses, question]);
   
    useEffect(() => {
      if (storedResponses && storedResponses[question.questionId]) {
          const response = storedResponses[question.questionId];
          if (Array.isArray(response)) {
              // Set selected options
              setSelectedValues(response.map(res => res.code));
          }
      }
  }, [storedResponses, question.questionId]);
  
    useEffect(() => {
      const currentOptions = question.options.filter(option => selectedValues.includes(option.code));
      const response = currentOptions.map(option => ({
          code: option.code
      }));
      if (selectedValues.length) {
          dispatch(setCurrentQuestion({ id: question.questionId, response }));
      }
  }, [selectedValues, dispatch, question.questionId, question.options]);


    const handleSelection = (code) => {
      let updatedValues = [];

      // Check if the question type is 'multi' for multi-selection mode
      if (question.type === 'multi') {
        if (selectedValues.includes(code)) {
          updatedValues = selectedValues.filter((v) => v !== code);
        } else {
          updatedValues = [...selectedValues, code];
        }
      } else {
        // Default to single selection mode
        updatedValues = [code];
      }

      setSelectedValues(updatedValues);
    };

    const handleZoomIn = (code) => {
      setZoomLevels(prevZoomLevels => ({
        ...prevZoomLevels,
        [code]: (prevZoomLevels[code] || 1) + 0.2
      }));
      openModal(code);
    };

    const openModal = (code) => {
      const selectedOption = question.options.find(option => option.code === code);
      setModalImage(selectedOption.image);
      // setModalImageCode(code);
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
      setModalImage(null);
      setModalImageCode(null);
    };

    const handleModalImageClick = () => {
      if (modalImageCode) {
        handleSelection(modalImageCode);
        closeModal();
      }
    };

    return (
      <>
        <div className="flex flex-col">
          {question.questionText && (
            <CustomQuestion
              id={`${question.questionId}_text`}
              key={`${question.questionId}_text`}
              text={parse(question.questionText)}
            />
          )}
   
          {question.questionInstruction && (
            <CustomInstruction
              id={`${question.questionId}_instruction`}
              key={`${question.questionId}_instruction`}
              text={parse(question.questionInstruction)}
            />
          )}
        </div>
   
        <div className="mt-4">
          <div className="image-card-container">
            {question.options.map((option, index) => (
              <div
                className={`image-card ${
                  selectedValues.includes(option.code) ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleSelection(option.code)}
              >
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(option.code)}
                    onChange={() => handleSelection(option.code)}
                  />
                </label>
   
                <div className="image-card-content">
                  <img
                    src={`/assets/${option.image}`}
                    alt={option.label}
                    width={option.width * 0.8} // Adjust size
                    height={option.height * 0.8}
                    className="image-card-img"
                    style={{ display: option.showImage ? "block" : "none" }}
                  />
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleZoomIn(option.code);
                    }}
                    className="zoom-button"
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                  </button>
                </div>
              </div>
            ))}
   
            {isModalOpen && (
              <div className="modal-overlay" onClick={closeModal}>
                <div className="modal-content" onClick={handleModalImageClick}>
                  <button className="close-button" onClick={closeModal}>
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </button>
                  <img
                    src={`/assets/${modalImage}`}
                    alt="Large view"
                    className="modal-image"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  export default ImageSingleSelectComponent;
