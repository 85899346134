import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

let responses = createSlice({
    name: "responses",
    initialState,
    reducers:{
        addResponse(state,action){
            return {
                ...state,...action.payload
            }
        }
    }

})


export const {addResponse} = responses.actions;

export default responses.reducer