import React, { useState } from "react";
import { Label } from 'reactstrap';
 
const StarRatingComp = ({ id, options, starRating, handleStarRatingChange }) => {
    const [selectedStarColor, setSelectedStarColor] = useState('');
 
    const handleRatingChange = (e, selectedCode) => {
        const selectedOption = options.find(option => option.code === selectedCode);
        setSelectedStarColor(selectedOption.color);
        handleStarRatingChange(e, selectedCode);
    };
 
    return (
        <div className="rating rating-lg  gap-1">
            {options.map((mark) => {
                const isSelectedOrBefore = starRating >= mark.code;
                const color = isSelectedOrBefore ? selectedStarColor || mark.color : 'base-bg';
 
                return (
                    <Label key={mark.code} className="radio-label">
                        <input
                            type="radio"
                            name={`${id}_${mark.code}`}
                            id={`${id}_${mark.code}`}
                            value={mark.code}
                            checked={starRating === mark.code}
                            onChange={(e) => handleRatingChange(e, mark.code)}
                            className={`mask mask-star-2 ${isSelectedOrBefore ? (color ? '' : 'selected-bg') : 'base-bg'}`}
                            aria-label={`Rating ${mark.label}`}
                            style={{ backgroundColor: color }}
                        />
                    </Label>
                );
            })}
        </div>
    );
};
 
export default StarRatingComp;