import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS
import "../ThumbRatingQuestion/ThumbRatingQuestion.css";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import { useDispatch, useSelector } from "react-redux";
import { Label, Input } from "reactstrap";
import parse from "html-react-parser";
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';



const ThumbRatingQuestion = ({ question }) => {
  const { options = [], id, questionText } = question;
  const responses = useSelector((state) => state.responses || {}); // Retrieve responses from Redux
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");



  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const response = responses[question.questionId];
      setSelectedOption(response || ""); // Update state when responses change
    }
  }, [responses, question.questionId]);



  useEffect(() => {
    const response = selectedOption || null;
    if (response !== null) {
      // Only dispatch if there is a selection
      dispatch(
        setCurrentQuestion({
          id: question.questionId,
          response: response,
        })
      );
    }
  }, [selectedOption, dispatch, question.questionId]);



  const handleResponseChange = (value) => {
    if (selectedOption === value) {
      setSelectedOption(""); // Deselect if the same option is clicked again
    } else {
      setSelectedOption(value);
    }
  };



  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}
        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>
      <div className="mt-4 flex flex-wrap">
        {options.map((option) => {
          const isSelected = selectedOption === option.code;
          // Get primary color
          const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
          // Determine border color based on option color or fallback to primary color
          const borderColorC = option.color || primaryColor;
          // console.log(`Option: ${option.code}, Selected: ${isSelected}, Border Color: ${borderColorC}`); // Log current state
          return (
            <CustomResponse key={option.code}>
              <Input
                type="radio"
                id={`${id}-${option.code}`}
                key={`${id}-${option.code}`}
                name={id}
                value={option.code}
                checked={isSelected}
                onChange={() => handleResponseChange(option.code)}
                style={{ display: "none" }}
              />
              <Label
                htmlFor={`${id}-${option.code}`}
                id={`${question.questionId}_${option.code}`}
                key={`${question.questionId}_${option.code}`}
                className={`thumb-rating-option mx-2 ${isSelected ? 'selected' : ''}`}
                style={{
                  borderColor: borderColorC, // Set border color for selected state or gray for unselected
                  backgroundColor: isSelected ? borderColorC : 'transparent', // Set background color for selected state
                  borderWidth: '2px', // Ensure border width is set
                  borderStyle: 'solid', // Ensure border style is solid
                }}
              >
                <div className="thumb-icon">
                  {option.code === "1" ? (
                    <i className="fa fa-thumbs-down"></i>
                  ) : (
                    <i className="fa fa-thumbs-up"></i>
                  )}
                </div>
                <div>
                  {parse(option.label)}
                </div>
              </Label>
            </CustomResponse>
          );
        })}
      </div>
    </>
  );
};
export default ThumbRatingQuestion;

