import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import { Card, CardTitle, FormGroup, Label, Input, Button } from 'reactstrap';
import parse from 'html-react-parser';
import randomizeOptions from "../../CommonComponents/Randomization";

import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import { CustomResponse } from '../../theme/ResponseLabel';

function HorizontalRatingScale({ question }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [randomizedOptions, setRandomizedOptions] = useState([]);
  const dispatch = useDispatch();
  const responses = useSelector(store => store.responses);

  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const response = responses[question.questionId];
      setSelectedOption(response || '');
    }
  }, [responses, question.questionId]);

  useEffect(() => {
    const response = selectedOption || null;
    if (response !== null) {
      dispatch(
        setCurrentQuestion({
          id: question.questionId,
          response: response,
        })
      );
    }
  }, [selectedOption, dispatch, question.questionId]);

  useEffect(() => {
    if (question.randomization) {
      setRandomizedOptions(randomizeOptions(question.options));
    } else {
      setRandomizedOptions(question.options);
    }
  }, [question.options, question.randomization]);

  const handleChange = (option) => {
    if (selectedOption === option.code) {
      setSelectedOption(''); // Deselect if the same option is clicked again
    } else {
      setSelectedOption(option.code);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>

      <div className="mt-4" style={{ width: 'max-content' }}>
      <CustomResponse className="gap-2">
          {randomizedOptions.map((option) => (
            <label
              id={`${question.questionId}_${option.code}`}
              key={`${question.questionId}_${option.code}`}
              className={`px-3 py-2 ${option.color ? (selectedOption === option.code ? 'text-white' : 'base-hbg') : (selectedOption === option.code ? 'selected-bg text-white' : 'base-hbg')} cursor-pointer`}
              onClick={(e) =>
              {          
                handleChange(option,e)}}
                style={{
                backgroundColor: selectedOption === option.code ? option.color : undefined,
                color: selectedOption === option.code ? 'white' : 'black',
                border: '2px solid transparent',
                boxShadow:'0 4px 8px rgba(0, 0, 0, 0.4)'
               
              }}
            >
              {parse(option.label)}
            </label>
          ))}
        </CustomResponse>
      </div>
    </>
  );
}


export default HorizontalRatingScale;
