import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const currentQuestionSlice = createSlice({
  name: "currentQuestion",
  initialState,
  reducers: {
    getcurrentQuestion(state, action){
      return state;
    },
    setCurrentQuestion(state, action){
      return action.payload;
    }
  }
});

export const { getcurrentQuestion, setCurrentQuestion } = currentQuestionSlice.actions;

export default currentQuestionSlice.reducer;
