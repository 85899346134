import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentQuestion } from "../../../redux/Reducers/currentQuestionSlice";
import StarRatingComp from "../../CommonComponents/StarRating";
import { CustomQuestion } from '../../theme/QuestionLabel';
import { CustomInstruction } from '../../theme/InstructionLabel';
import parse from 'html-react-parser';

function StarRatingMode({ question }) {
  const { id, options } = question;
  const [starRating, setStarRating] = useState("");
  const dispatch = useDispatch();
  const responses = useSelector((store) => store.responses);

  useEffect(() => {
    if (responses && responses[question.questionId]) {
      const savedResponse = responses[question.questionId].toString();
      setStarRating(savedResponse);
      dispatch(
        setCurrentQuestion({
          id: question.questionId,
          response: savedResponse,
        })
      );
    }
  }, [responses, question.questionId, dispatch]);

  const handleStarRatingChange = (event, option) => {
    const newRating = event.target.value;
    setStarRating(newRating);
    dispatch(
      setCurrentQuestion({
        id: question.questionId,
        response: newRating,
        selectedOption: option,
      })
    );
  };

  return (

    <>
      <div className="flex flex-col">
        {question.questionText && (
          <CustomQuestion
            id={`${question.questionId}_text`}
            key={`${question.questionId}_text`}
            text={parse(question.questionText)}
          />
        )}

        {question.questionInstruction && (
          <CustomInstruction
            id={`${question.questionId}_instruction`}
            key={`${question.questionId}_instruction`}
            text={parse(question.questionInstruction)}
          />
        )}
      </div>
      <div className="mt-4">
        <StarRatingComp
          id={question.questionId}
          options={options}
          starRating={starRating}
          handleStarRatingChange={handleStarRatingChange}
        />
      </div>

    </>
  );
}

export default StarRatingMode;