import React from 'react';
import { Input } from 'reactstrap';

const OtherTextInput = ({ value, onChange, placeholder, id, className }) => {
  return (
    <Input
      id={id}
      type="text"
      className={`other-input ml-2 p-1 button-width ${className}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default OtherTextInput;
