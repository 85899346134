import React from "react";
import "../../index.css";

export const CustomButton = ({ id, children, onClick, className = "", ...props }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`btn btn-primary border-none px-4 mx-1 text-white text-xl button-color ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
